import { RouteComponentProps, Router } from '@reach/router'
import React from 'react'
import Layout from 'src/components/layout/layout'
import Member from 'src/components/member/member'
import SEO from 'src/components/seo'
import { usePrivateRoute } from 'src/utils/auth'

const MemberPage = () => {
  usePrivateRoute()
  return (
    <Layout>
      <SEO title="Member" />
      <Router basepath="/memberships/member">
        <MemberRoute path="/:memberId" />
      </Router>
    </Layout>
  )
}

interface MemberRouteProps extends RouteComponentProps {
  memberId?: string
}

const MemberRoute: React.FC<MemberRouteProps> = ({ memberId }) => {
  return <Member memberId={memberId} />
}

export default MemberPage
